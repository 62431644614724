import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Four Pillars",
  "date": "2019-04-25T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`1) suffering is an innate characteristic of existence in the realm of samsara
2) (origin, arising, combination; 'cause') together with suffering arises "(craving, desire or attachment, lit. "thirst").
3) (cessation, ending, confinement): suffering can be ended or contained by the renouncement or letting go of desire, the confinement of desire releases the excessive bind of suffering
4) (path, Noble Eightfold Path) is the path leading to the confinement of desire and suffering`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      